<template>
<b-badge :variant="role.color">
  {{ role.label }}
</b-badge>
</template>
<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    rolesList: {
      type: Array,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      role: {
        text: '',
        value: '',
        color: 'secondary',
      },
    }
  },
  created() {
    // eslint-disable-next-line no-restricted-syntax
    for (const i in this.rolesList) {
      if (this.rolesList[i].value === this.userRole) {
        this.role = Object.assign(this.role, this.rolesList[i])
      }
    }
  },
}
</script>
