<template>
  <div>
    <!-- TODO: ADD USER FILTERS -->
    <b-card
      no-body
      class="mb-0"
    >
      <v-table
        ref="data-list"
        path="users"
        :fields="tableColumns"
        :filter="{ ...searchFilter, search }"
        table-sm
        @create="onUserCreateHandler"
      >
        <template #cell(serial)="data">
          <v-table-serial-column :data="data" />
        </template>
        <template #cell(role)="{ item }">
          <user-role-badge
            :user-role="item.role"
            :roles-list="roles"
          />
        </template>
        <template #cell(actions)="{ item }">
          <v-table-actions-column
            @view="$router.push({ name: 'user-view', params: { id: item.id } })"
            @edit="editUserHandler(item)"
            @delete="deleteUserHandler(item)"
          />
        </template>
      </v-table>
    </b-card>
    <b-modal
      id="user-form"
      hide-footer
      title="Редактировать пользователя"
      @hidden="resetUserForm"
    >
      <user-form
        :user-data="userForm"
        @submit="submitForm"
        @cancel="closeFormModal"
      />
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import {
  BCard,
  BModal,
} from 'bootstrap-vue'
import VTable, {
  VTableSerialColumn,
  VTableActionsColumn,
} from '@/views/components/vtable'
import UserRoleBadge from './UserRoleBadge.vue'
import UserForm from './UserForm.vue'
import { roles, tableColumns } from './usersData'

const emptyUser = {
  name: '',
  email: '',
  role: 'disabled',
}

export default {
  components: {
    BCard,
    VTableSerialColumn,
    VTable,
    VTableActionsColumn,
    UserRoleBadge,
    UserForm,
    BModal,
  },
  data() {
    return {
      searchQuery: '',
      searchFilter: {},
      searchTimeout: null,
      isSidebarActive: false,
      userForm: {},
      roles,
      tableColumns,
    }
  },
  computed: {
    search() {
      return this.$store.getters['app/search']
    },
  },
  methods: {
    refreshTable() {
      this.$refs['data-list'].updateTable()
    },
    closeFormModal(refreshTable = false) {
      this.$bvModal.hide('user-form')
      if (refreshTable) {
        this.refreshTable()
      }
    },
    deleteUserHandler(data) {
      this.$bvModal.msgBoxConfirm(`Удалить пользователя "${data.name}"?`)
        .then(result => {
          if (result) {
            this.$axios.delete(`users/${data.id}`)
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
    createUserHandler(data) {
      this.$axios.post('/users', data)
        .then(() => this.closeFormModal(true))
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: 'Ошибка',
            autoHideDelay: 5000,
            variant: 'danger',
          })
        })
    },
    updateUserHandler(id, data) {
      this.$axios.put(`/users/${id}`, data)
        .then(() => this.closeFormModal(true))
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: 'Ошибка',
            autoHideDelay: 5000,
            variant: 'danger',
          })
        })
    },
    editUserHandler(user) {
      Vue.set(this, 'userForm', user)
      this.$bvModal.show('user-form')
    },
    resetUserForm() {
      this.userForm = { ...emptyUser }
    },
    submitForm(userData) {
      if (userData.id) {
        this.updateUserHandler(userData.id, userData)
      } else {
        this.createUserHandler(userData)
      }
    },
    onUserCreateHandler() {
      this.$bvModal.show('user-form')
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  min-width: 90px;
}
</style>
